export default {
  WETH: {
    address: '0x4200000000000000000000000000000000000006',
    logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
  USDC: {
    address: '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
    logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/base/assets/0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA/logo.png',
  },
  DAI: {
    address: '0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb',
    logo: 'https://basescan.org/token/images/daistablecoin_32.png',
  },
  WETH_TESTNET: {
    address: '0x232a7dc095cd39400b662f165919a6707056f964',
    logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo.png',
  },
  USDT_TESTNET: {
    address: '0x71EF937E2e84E1bE1181C3D2BF03AD86C36D3Fa1',
    logo: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xdAC17F958D2ee523a2206206994597C13D831ec7/logo.png',
  },
  BUSD_TESTNET: {
    address: '0x840acf913be69cf59f675ed5bd6aaa60e32023f4',
    logo: 'https://raw.githubusercontent.com/Pymmdrza/Cryptocurrency_Logos/mainx/SVG/busd.svg',
  },
}
